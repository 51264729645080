/* You can add global styles to this file, and also import other style files */
@import "~ng-pick-datetime/assets/style/picker.min.css";

span#priority, span#type, span#status, span.subelementmarker, span.subelementmarker2, span.brief {
    margin: 4px 2px;
}

.brief{
    font-size: 18px;
}

.red span {
    background-color :red;
    color: black;
    font-weight : bold;
    width: max-content;
}

.green span {
    background-color :green;
    color: black;
    font-weight : bold;
    width: max-content;
}

.smoke span {
    background-color : #3b3a30;
    color: white;
    font-weight : bold;
}

.blue span {
    background-color : rgb(14, 53, 158);
    color: white;
    font-weight : bold;
}

.hidden {
    display: none !important;
}

@media screen and (max-width: 999px) {

    .filterPanel, .detailsPanel, .overviewSetGroup, .overviewEstimation, #openLegend, .header {
        display: none !important;
    }

    .mobileButtons {
        display: block !important;
    }

    .estimation_and_groups_and_buttons {
        width: 60%; float: unset !important;
        margin-bottom: unset !important;
    }

    #openConfig {
        float: none !important;
    }
}

.estimation_and_groups_and_buttons {
    width: 60%; float: left;
    margin-bottom: 10px
}

/* .tabs__panel {
    background: #E0E9E9 !important;
}

.tabs__tab.active {
    background-color: #A0CE4D !important;
    background: #A0CE4D !important;
} */

xodos-estimation {
    position: initial !important;
}

xodos-comment-list {
    position: initial !important;
}

xodos-status-change-list {
    position: initial !important;
}

xodos-worklog-list {
    position: initial !important;
}

xodos-history-list {
    position: initial !important;
}

#out{
    background: #A0CE4D !important;
    border-color: #A0CE4D !important;
    top: 25%;
    position: absolute;
    right: 1%;
    height: 34px;
}

.xodosBtn{
    background: #A0CE4D !important;
    border-color: #A0CE4D !important;
}

.templateButton{
    padding: 5px;
}

body{
    font-size: 16px !important;
}

tabs ul {
    height: 65px;
}
